* {
  padding: 0;
  margin: 0;
  font-family: Manrope, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0rem 0.5rem 0rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.2rem;
}

p {
  margin: 0.5rem 0rem 0.5rem 0rem;
  font-size: 1.1rem;
}

li {
  margin: 0.5rem 0rem;
  font-size: 1.1rem;
}

hr {
  margin: 1rem 0rem 1rem 0rem;
}

a {
  color: #ff9532 !important;
  font-weight: 600;
}

.iframe-holder {
  min-width: calc(50% - 0.5rem);
  height: calc(100vh / 2);
  margin-bottom: 1rem;
}

.code {
  position: relative;
}

.code pre {
  transition: .3s ease all;
}

.code__icons {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.code__icons button {
  background-color: #2E3440;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #ffffff;
}

hr {
  border: none;
  border-bottom: 1px solid #e0e0e0
}

@media screen and (max-width: 780px) {
  .iframe-holder {
    min-width: calc(100%);
  }
}

iframe {
  border: 1px solid #e0e0e0;
}

a {
  color: #181818;
  text-underline-position: under;
  margin: 0;
  padding: 0
}

::-moz-selection {
  color: #181818;
  background: #f9e3cf;
}

::selection {
  color: #181818;
  background: #f9e3cf;
}